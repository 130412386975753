<script setup lang="ts">
import { computed, onMounted, onUnmounted, Ref, ref, watch } from 'vue'
import { RoomType } from '@/Pages/the-home/enums/Search'
import TheModal from '@/Components/the-modal/TheModal.vue'
import DateRangePicker from '@/Components/DateRangePicker.vue'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { router } from '@inertiajs/vue3'

import { vOnClickOutside } from '@vueuse/components'
import RoomTypeSwitcher from '@/Components/RoomTypeSwitcher.vue'
import SearchCounter from '@/Components/SearchCounter.vue'
import { useLocationSearch } from '@/Composables/locationSearch'
import AutocompleteSuggestions from '@/Components/AutocompleteSuggestions.vue'
import { useEscClose } from '@/Composables/use-esc-close'

type Props = {
    searchPlaceholder?: string
}

withDefaults(defineProps<Props>(), {
    searchPlaceholder: 'Where are you traveling?'
})

const guests = ref(1)
const roomType: Ref<RoomType> = ref(RoomType.DORM)
const rooms = ref(1)
const skipDate = ref(false)
const isRoomsQuantityVisible = ref(false)
const isDatepickerVisible = ref(false)
const dateRange: Ref<string[]> = ref([dayjs().format('YYYY-MM-DD'), dayjs().add(2, 'day').format('YYYY-MM-DD')])
const searchInputRef: Ref<HTMLInputElement | null> = ref(null)
const currency = ref('USD')

const {
    isOpened,
    search,
    query,
    url,
    autosuggestionResults,
    autosuggestionResultsVisible,
    isSearchInputValid,
    handleSearchInputClick,
    selectPlace,
    isLoadingSuggestions,
    getFirstSearchQuery,
    getSuggestions
} = useLocationSearch()

const handleEscKey = (): void => {
    if (isDatepickerVisible.value) {
        closeDateModal()
    } else if (autosuggestionResultsVisible.value) {
        autosuggestionResultsVisible.value = false
    } else if (!isDatepickerVisible.value && !autosuggestionResultsVisible.value) {
        closeSearch()
    }
}

onMounted(async () => {
    await getSuggestions()
    isOpened.value = true
    autosuggestionResultsVisible.value = true
    searchInputRef.value?.focus()

    window.addEventListener('keydown', useEscClose(handleEscKey))
})

watch(roomType, newType => {
    isRoomsQuantityVisible.value = newType === RoomType.PRIVATE
})


const startDate = computed(() => dayjs(dateRange.value[0]).format('YYYY-MM-DD'))
const endDate = computed(() => dayjs(dateRange.value[1]).format('YYYY-MM-DD'))

const inputDateRange = computed(() => `${ dayjs(dateRange.value[0]).format('D MMM') } - ${ dayjs(dateRange.value[1]).format('D MMM') }`)

const searchPayload = computed(() => ({
    searchOptions: {
        startDate: startDate.value,
        endDate: endDate.value,
        roomType: roomType.value,
        people: guests.value,
        rooms: rooms.value,
        groupType: '',
        groupAgeRanges: [],
        currency: currency.value,
        skipDate: skipDate.value
    }
}))

const isLoading = ref(false)

function closeDateModal ():void  {
    isDatepickerVisible.value = false
    autosuggestionResultsVisible.value = false
}

function submit (): void {
    if (!query.value) {
        isSearchInputValid.value = false
        searchInputRef.value?.focus()
        return
    }

    const currencyForServer = localStorage.getItem('currencyForServer')

    if (currencyForServer) {
        currency.value = currencyForServer
    }

    isLoading.value = true
    
    router.post(url.value, searchPayload.value, {
        onFinish: () => {
            isLoading.value = false
        }
    })
}

function onSkipDates (): void {
    isDatepickerVisible.value = false
    skipDate.value = true

    submit()
}

function closeSearch (): void  {
    isOpened.value = false
    autosuggestionResultsVisible.value = false
}

const showAutocompleteSuggestions = computed(() => !isLoadingSuggestions.value && autosuggestionResultsVisible.value && !isDatepickerVisible.value)

onUnmounted(() => {
    window.removeEventListener('keydown', useEscClose(handleEscKey))  
})

</script>

<template>
    <div class="home-hero-search">
        <div
            id="heroSearch"
            :class="{ heroSearchSmall: !isOpened }"
        >
            <div id="date-search-container" class="search-bar-items">
                <div id="locationHero" class="search-bar-item input-icon input-search">
                    <input
                        ref="searchInputRef"
                        v-model.trim="search"
                        v-on-click-outside="() => autosuggestionResultsVisible = false"
                        type="text"
                        class="websiteIndexSearch form-control"
                        :class="{ 'is-invalid': !isSearchInputValid && isOpened }"
                        :placeholder="searchPlaceholder"
                        required
                        @click="handleSearchInputClick"
                        @keyup.enter="isDatepickerVisible = true; autosuggestionResultsVisible = false, getFirstSearchQuery()"
                    >

                    <div v-if="isLoadingSuggestions && !isDatepickerVisible" class="spinner-wrap mt-3 ml-5" :class="{'hide-elements' : !isOpened}">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <AutocompleteSuggestions
                        :suggestions="autosuggestionResults"
                        :do-animation="showAutocompleteSuggestions"
                        @select-place="selectPlace($event); isDatepickerVisible = true"
                    />
                </div>

                <div class="controller-wrap" :class="{'hide-elements' : !isOpened}">
                    <div class="search-form-index">
                        <div class="bookingSearchForm d-flex flex-row bk-search bk-search--index justify-content-between">
                            <div class="my-lg-5">
                                <label class="pre-title cl-subtext" for="searchDate">Add dates</label>
                                <div class="datepicker-container datepicker-container-left input-icon input-calendar">
                                    <input
                                        id="searchDateHeroTitle"
                                        type="text"
                                        class="form-control cursor-pointer"
                                        :value="inputDateRange"
                                        @click="isDatepickerVisible = true"
                                    >
                                </div>
                            </div>

                            <div class="my-lg-5">
                                <RoomTypeSwitcher v-model="roomType" class="cl-subtext" />
                            </div>

                            <div v-if="isRoomsQuantityVisible" class="my-lg-5 bookingSearchRooms">
                                <SearchCounter v-model="rooms" label="Rooms" class="cl-subtext" />
                            </div>

                            <div class="my-lg-5">
                                <SearchCounter v-model="guests" label="Guests" class="cl-subtext" />
                            </div>
                        </div>
                    </div>

                    <div class="search-bar-item">
                        <button
                            type="button"
                            class="btn bg-primary text-white btn-block mt-2 mt-sm-0 text-nowrap"
                            @click.stop="submit"
                        >
                            <div class="d-flex align-items-center justify-content-center">
                                <div v-if="isLoading" class="spinner-border mr-2" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                                <FontAwesomeIcon v-else class="mr-2" :icon="faSearch" />

                                <span>Search</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <TheModal
            :show="isDatepickerVisible"
            hidden-close-icon
            @close="closeDateModal"
        >
            <div class="picker-wrapper">
                <DateRangePicker v-model="dateRange" @dates-selected="closeDateModal" />
            </div>

            <template #footer>
                <button
                    type="button"
                    class="btn btn-sm btn-primary mr-auto"
                    @click="onSkipDates"
                >
                    skip dates
                </button>
            </template>
        </TheModal>
    </div>
</template>

<style lang="scss" scoped>
.autocomplete-suggestions {
    position: absolute;
    max-height: 300px;
    z-index: 1100;
    width: 876px;
}

.autocomplete-suggestion {
    &:hover {
        background-color: #ECEFF4;
    }
}

.picker-wrapper {
    padding: 16px 15px;
}

.controller-wrap  {
    transition: max-height .2s, opacity 0.2s, visibility 0.2s, transform 0.2s;
}

.hide-elements {
    @media (min-width: 992px) {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-30%);
        padding: 0 32px;
    }
} 

.spinner-border {
    width: 1rem;
    height: 1rem;
    border: 0.2em solid currentColor;
    border-right-color: transparent;
} 
</style>